/* .profilemain {
    display: flex;
    align-items: center;
    font-size: 20px;
    flex-direction: column;
    width: 60%;
    padding: 40px;
    margin: auto;
    margin-top: 100px;
    border: 1px solid black;
    border-radius: 20px;
} */

/* .profile_arrange{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
*/
.sellnow_submit{
    width: 100px;
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    background-color: #000000;
    color: white;
    border-radius: 10px;
}

.profile_submit:focus{
    outline: 0;
} 