
.searchFilter{
    width: 90%;
    margin: 50px 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productsinput {
    width: 100%;
    height: 42px;
    margin-right: 25px;
    outline: none;
    border: 1px solid #868383;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 500;
    padding-left: 20px;
    font-size: 24px;
    line-height: 80px;
    border-radius: 10px;
}

.sortoptions{
    position: absolute;
    max-height: 300px;
    top: 50px;
    left: 1px;
    background-color: white;
    width: 150px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.18);
    cursor: pointer;
    border-radius: 10px;
    background: rgba(234, 184, 60, 0.85);
}

.productdropdown{
    max-height: 300px;
    background-color: white;
    width: 165px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.18);
    cursor: pointer;
    border-radius: 10px;
    background: rgba(234, 184, 60, 0.85);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    height: 36px;
    padding: 10px 0 0 7px;
    color: white;

}

.productdropdown:hover{
    background: rgba(234, 184, 60, 0.90);

}

.productspageproductscontainer{
    display: flex;
    justify-content: center;
    margin: 0 120px;
    gap: 150px;
    flex-wrap: wrap;

}

@media screen and (max-width: 768px) {
    .productspageproductscontainer{
        margin: 0 10px;
        gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}