@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans');

@font-face {
    font-family: 'waranty';
    src: url('../../assets/fonts/waranty.otf');
}

@font-face {
    font-family: 'guesta';
    src: url('../../assets/fonts/guesta.otf');
}

@font-face {
    font-family: 'Glorify DEMO';
    src: url('../../assets/fonts/Glorifydemo.otf');
}

@font-face {
    font-family: 'techead';
    src: url('../../assets/fonts/techead.otf');
}

.mainview {
    background-image: url('../../../public/images/home.png');
    position: relative;
    height: calc(100vh - 50px);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 50px;
}

.main_header_ham {
    display: none;
}

.main_header {
    width: 85%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    margin-right: 15%;
    margin-bottom: 20px;
}

.main_header a {
    margin-right: 200px;
    font-size: 22px;
    color: #D0BA95;
    text-decoration: none;
}


.main_details {
    margin: 7% 55% 0 100px;
}

.main_title {
    color: rgb(255, 210, 99);
    font-family: 'waranty';
    font-size: max(12vw, 2vh);
    margin: 0;
}

.main_title span {
    color: white;
}

.main_subtitle {
    color: white;
    font-size: max(1.5vw, 1.5vh);
    font-family: 'Montserrat';
}

.main_input {
    height: 50px;
    width: 50%;
    border-radius: 50px;
    background: transparent;
    outline: none;
    border: 1px solid white;
    font-size: 160%;
    color: white;
    padding-left: 25px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;

}

.main_input:focus {
    outline: none;
}

.rounded_button {
    cursor: pointer;
    border-radius: 50px;
    padding: 0 50px 0 50px;
    margin-right: 50px;
    width: 200px;
    height: 62px;
    font-size: 22px;
    font-weight: 600;
    font-family: 'PT Sans', sans-serif;
}

.rounded_button a {
    text-decoration: none;
}

.button_wrap {
    margin-top: 150px;
}

.buy_now_button {
    background: #F2AD01;
    border-radius: 50px;
    color: white;
    border: none;
}

.buy_now_button a {
    color: white;
}

.bodybody {
    background: linear-gradient(180deg, #F2F2F5 0%, #E4F7FF 100%);
}

.sell_now_button {
    background: transparent;
    color: white;
    border: 1px solid #F2AD01;
}

.newest_arrivals_heading {
    font-family: 'Montserrat';
    margin: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
}

.newest_arrivals_cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 8% 0 8%;
    justify-content: space-evenly;
    gap: 50px;
}


.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;
    height: 40px;
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

.login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
    background-color: #eeeeee;
}

.login-with-google-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}


@media screen and (max-width: 768px) {

    /* .mainview {
        width: calc(100vw - 50px);
    } */
    .mainview {
        overflow-x: hidden;
        padding-top: 1px;
        height: 100svh;
    }

    .main_header a {
        text-decoration: none;
        margin: 15px;
    }

    .main_header_ham {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        background-color: rgba(105, 105, 105, 0.15);
        backdrop-filter: blur(5px);
    }

    .main_header {
        position: fixed;
        display: flex;
        flex-direction: column;
        transition: height 500ms;
        overflow: hidden;
        margin: 0;
        width: 100%;
        background-color: rgba(105, 105, 105, 0.15);
        backdrop-filter: blur(5px);
    }

    .main_details {
        
        margin: 50% 0 0 0;

    }

    .main_title {
        /* font-size: 80px; */
        margin-top: -5rem;
        font-size: 30vw;
        text-align: center;
    }

    .main_subtitle {
        /* display: none; */
        text-align: center;
        font-size: 20px;
    }

    /* .main_subtitle::after {
        color: red;
        font-size: 12px;
        white-space: pre;
        word-wrap: break-word;
        content: 'For now site is optimized for desktop';
    } */
    .button_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        gap: 10px;
    }

    .rounded_button {
        /* padding: 0 50px 0 50px; */
        /* margin-right: 50px; */
        width: 45%;
        height: 45px;
        /* font-size: 20px; */
        font-size: 0.9rem;
        margin-right: 0;
    }

    .productsnavbar {
        display: none;
    }

    .newest_arrivals_cont {
        display: flex;
        flex-wrap: wrap;
    }



}
