@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans');

@font-face {
    font-family: 'waranty';
    src: url('../../assets/fonts/waranty.otf');
}

@font-face {
    font-family: 'techead';
    src: url('../../assets/fonts/techead.otf');
}

.product_main_view {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 7% 0 7%;
    /* justify-content: center; */
}

.product_page_details {
    width: 100%;
    height: 100%;
    /* padding: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product_page_price {
    height: 29px;
    margin-top: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.product_page_hr {
    width: 90%;
    height: 0;
    border: 3px solid #D9D9D9;
    margin-left: 0;
    margin-top: 15px;
}

.product_page_description {
    min-height: 10%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 50px;
    /* or 150% */

    letter-spacing: -0.02em;
}

.product_page_cart {

    border-radius: 20px;
    width: fit-content;
    outline: 0;
    padding: 0px 31px;
    /* padding: 0px 20px; */
    /* margin-right: 20px; */
    /* width: 170px; */
    height: 45px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 45px;
    border: 0;

}

.the_buttons {
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-top: 20px;
  }


.cart_white{
    background: transparent;
    border: 1px solid black;
}

.cart_white_negotiate{
    background: transparent;
    margin-left: 80px;
    border: 1px solid black;
}

.cart_black{
    background: #303030;
    color: white;
}

.product_page_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
}

.product_page_images {
    height: 50%;
    width: 100%;
    background-size: 'cover';
    background-repeat: 'no-repeat';
    background-position: 'center';
}

.product_page_image{
    display: flex;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .the_buttons{
        flex-direction: column;
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        width: 100%;
    }
    .product_page_cart{
        flex-direction: column;
        margin: 5px;
    }

    .product_main_view{
        display: flex;
        flex-direction: column-reverse;
    }
    .product_page_image{
        height: 100%;
    }

    .product_page_details{
        flex-grow: 1;    
    }
     /* .cart_white_negotiate{
       margin-left: auto;
      
    } */
    /* .cart_black{
       margin-right:3rem;
       margin-left: -2rem;
    } */
}
