.footer {
  overflow-x: hidden;
  display: grid;
  height: 360px;
  padding-top: 50px;
  padding-bottom: 50px;
  grid-template-columns: 1fr 15px 2fr;
  margin-top: 200px;
  margin-bottom: 0;
  background-color: rgba(22, 32, 57, 1);
}

.footerwrap {
  background-color: rgba(22, 32, 57, 1);
}

.group_name_header {
  color: #fdd43e;
  font-family: "waranty";
  font-size: 80px;
  text-align: center;
}

.group_name_header span {
  color: white;
}

.group_name_text {
  font-family: "techead";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 50px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  margin-top: 10px;
}

.group_name_company {
  font-family: "guesta";
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 60px;
  color: white;
  text-align: center;
}

.footer_address {
  font-family: "Glorify DEMO";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.3);
  margin-top: 20px;
  margin-left: 65px;
}

.footercontactus_text {
  font-family: "Glorify DEMO";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 50px;
  width: 80%;
  padding-top: 30px;
  margin: auto;
  color: #fdd43e;
  text-align: center;
}

.footercontactus_text_text {
  padding-top: 30px;
}

.contact_details_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
}

.contact_details_name,
.contact_details_action {
  color: white;
  font-family: "Glorify DEMO", Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 40px;
}

.Contact_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
}

.linked {
  margin-right: 10px;
  margin-bottom: 3px;
  width: auto;
  height: 36px;
}

.Gmail {
  margin-left: 15px;
  margin-right: 15px;
  width: auto;
  height: 33px;
}

.Phone {
  margin-left: 15px;
  height: 33px;
  width: auto;
}

.copyrightandweb {
  color: white;
  display: flex;
  justify-content: center;
}

.copyrightandweb p {
  text-align: center;
  margin: 0;
}

.copyrightandweb p {
  text-align: center;
  margin: 0;
}

.copyrightandweb a {
  color: #fdd43e;
  text-decoration: underline;
}

.copyrightandweb {
  padding: 10px 0;
}


/* Media queries */
@media only screen and (max-width: 767px) {
  .footer {
    display: block;
    height: auto;
    margin-top: 100px;
    padding: 30px 0;
  }

  .group_name_header {
    font-size: 60px;
  }

  .group_name_company {
    font-size: 40px;
    line-height: 50px;
  }

  .footer_address {
    margin: 20px auto;
    text-align: center;
  }

  .footercontactus_text {
    font-size: 50px;
    padding-top: 10px;
  }

  .contact_details_container {
    flex-direction: column;
    height: auto;
    justify-content: center;
  }

  .Contact_logo {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .contact_details_name,
  .contact_details_action {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-top: 15px;
  }

  .linked {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  .Gmail {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  .Phone {
    margin-left: 15px;
    height: 32px;
  }
  .copyrightandweb {
    flex-direction: column;
  }
}
