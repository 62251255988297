.cart_page_main {
    margin-top: 50px;
}

.mycartheading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 10px;
    margin-left: 50px;
    margin: 80px 15%;
}

.cart_details_final {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    justify-content: flex-end;
    margin: 30px 15%;
}

.cart_details_final div {
    display: flex;
    align-items: center;
}

.cart_details_final p {
    margin-left: 80px;
}

.cart_details_actions {
    margin: 30px 25%;
    display: flex;
    justify-content: space-evenly;
}

.vertical_bar{
    width: 0;
    border: 1px solid black;
}

.cartpopupwrap{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;  
    height: 90vh;
    z-index: 100;
}

.cartpopup{
    background-color: white;
    box-shadow: -2px 4px 15px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    width: 45%;
}

.popup_head{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    font-size: 50px;
    gap: 10px;
    align-items: center;
}

.popupcontent{
    display: flex;
    flex-direction: column;
    margin: 0 5%;
}

.cart_submit_button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;    

}

@media screen and (max-width: 768px) {
    .mycartheading{
        margin: 70px 0 50px 5%;
    }

    .cart_details_actions{
        margin: 30px 1%;
    }

    .cartpopup{
        height: calc(100vh - 100px);
        width: 100%;
        overflow-y: scroll;
    }

    .cart_details_final p{
        margin-left: 10px;
    }

    .product_cart_desc_cont{
        flex-direction: column;
    }
}
