@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans');

@font-face {
    font-family: 'waranty';
    src: url('../../fonts/waranty.otf');
}

@font-face {
    font-family: 'techead';
    src: url('../../fonts/techead.otf');
}



.productview {
    display: flex;
    flex-direction: column;
    height: 412px;
    width: 288px;
    border: 1px solid #FFFFFF;
    box-shadow: -2px 4px 15px rgba(0, 0, 0, 0.18);
    border-radius: 30px;
}

.productdate {
    margin: 15px;
    text-align: right;
}

.productimage {
    flex-grow: 1;
}

.details {
    margin: 25px;
}

.product_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.product_price {
    margin-top: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
}

.add_button {
    font-family: 'PT Sans';
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    background: transparent;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 18px;

}

.Negotiate {
    font-family: 'Montserrat';
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    background: transparent;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 18px;
}

@media screen and (max-width: 768px) {
    .productview {
        width: 14rem;
        height: 25rem;
    }
}
