@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans');

@font-face {
    font-family: 'waranty';
    src: url('../../fonts/waranty.otf');
}

@font-face {
    font-family: 'techead';
    src: url('../../fonts/techead.otf');
}


.product_cart_main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 30px 15%;
}

.product_cart_name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.product_cart_price {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.product_cart_heading {
    display: flex;
    justify-content: space-between;
}

.product_cart_hr {
    width: 100%;
    height: 0;
    border: 1px solid #D9D9D9;
    margin-left: 0;
    margin-top: 15px;
}

.product_cart_remove {
    font-size: 150%;
    cursor: pointer;
    text-align: end;
}

.product_cart_desc_cont {
    display: flex;
    margin: 30px 10px;
}

.product_cart_description {
    padding: 30px 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .product_cart_main {
        display: flex;
        flex-direction: column;
        margin: 30px 20px;
        box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.18);
        padding: 50px 20px;
        border-radius: 15px;
    }

    .hr_wrap {
        display: none !important;
    }

    .product_cart_image {
        height: 100px;
        margin: 0 0 50px 0;
    }

    .product_cart_remove {
        font-size: 120%;
    }
}