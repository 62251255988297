.profilemain {
    display: flex;
    align-items: center;
    font-size: 20px;
    flex-direction: column;
    width: 60%;
    padding: 40px;
    margin: auto;
    margin-top: 100px;
    border: 1px solid black;
    border-radius: 20px;
}

.profile_arrange{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.profile_submit{
    width: 100px;
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    background-color: rgb(255, 210, 99);
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.profile_submit:focus{
    outline: 0;
}

@media screen and (max-width: 768px) {
    .profilemain{
        overflow-x: hidden;
        width: calc(100% - 40px);
        margin: 50px 10px;
        padding: 10px;
    }

    .profile_arrange{
        display: grid;
        grid-template-columns: 1fr;
    }
}