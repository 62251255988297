.myorderscont {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

}

.myordersheading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    margin: 0 50px;
}